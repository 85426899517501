<template>
  <div class="inline-flex items-center mb-5 cursor-pointer" @click="back">
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <transaction />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: () => {
    const router = useRouter();
    const back = () => router.push({ name: 'Dashboard' });
    return { back };
  },
});
</script>
